<template>
  <v-container
    v-if="account"
    fluid
    fill-height
    class="pa-3 align-start justify-start"
  >
    <v-overlay
      v-if="loading"
      absolute
      :value="loading"
      opacity="1"
      color="white"
      class="full-screen"
      text-xs-center
    >
      <div class="d-flex flex-column justify-center align-center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        />
        <img
          src="@/assets/Lavirra_logo_SVG.svg"
          height="28px"
          width="auto"
          class="mt-6"
        >
        <span
          class="mt-4 ml-2"
          :style="`font-size: 15px; color: ${$vuetify.theme.themes.light.primary};`"
        >
          {{ message }}
        </span>
      </div>
    </v-overlay>
    <v-card
      height="100%"
      width="100%"
      :style="`height: ${($vuetify.breakpoint.height - 70).toString() + 'px'}; overflow-y: auto; overflow-x: hidden;`"
    >
      <v-toolbar
        flat
        rounded
        dense
        color="white"
        class="pt-1"
        style="position: sticky; top: 0px; z-index: 1;"
      >
        <v-tabs
          v-model="selectedTab"
          active-class="white"
          slider-color="accent"
          grow
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.index"
            :disabled="tab.disabled"
            class="mb-1"
          >
            {{ tab.text }}
          </v-tab>
        </v-tabs>
      </v-toolbar>
      <v-row
        class="mb-1 py-1"
      >
        <v-col cols="12">
          <v-tabs-items v-model="selectedTab">
            <v-tab-item>
              <v-container
                fluid
                :style="`height: ${($vuetify.breakpoint.height - 145).toString() + 'px'};` "
              >
                <v-card
                  width="100%"
                  height="100%"
                  flat
                  class="ma-0"
                  :style="`height: ${($vuetify.breakpoint.height - 155).toString() + 'px'}; overflow-y: auto; overflow-x: hidden;` "
                >
                  <v-row>
                    <v-avatar
                      size="80"
                      color="primary"
                      class="ml-8 mt-8"
                      @mouseover="hoverAvatarIcon = true"
                      @mouseleave="hoverAvatarIcon = false"
                    >
                      <img
                        v-if="account && account.avatar && !hoverAvatarIcon"
                        :key="componentKey"
                        :src="`${cfg.baseURL}downloadAvatar/${account._id}/${account.avatar.raw.filename}`"
                      >
                      <v-icon
                        v-if="hoverAvatarIcon"
                        large
                        color="white"
                        class="icon grow-class"
                        @click="uploadProfilePicture"
                      >
                        mdi-plus-circle-outline
                      </v-icon>
                      <v-icon
                        v-if="!hoverAvatarIcon && !account.avatar"
                        dark
                        size="40"
                      >
                        mdi-account-circle
                      </v-icon>
                    </v-avatar>
                    <my-upload
                      v-model="show"
                      field="img"
                      :width="300"
                      :height="300"
                      :url="`${cfg.baseURL}uploadAvatar/${account._id}`"
                      :params="params"
                      :headers="headers"
                      img-format="png"
                      lang-type="en"
                      :no-square="true"
                      @crop-success="cropSuccess"
                      @crop-upload-success="cropUploadSuccess"
                      @crop-upload-fail="cropUploadFail"
                      @src-file-set="srcFileSet"
                    />
                  </v-row>

                  <!-- lawyer account type form -->
                  <v-form
                    v-if="account.accountType === 'lawyer'"
                    autocomplete="off"
                  >
                    <v-row
                      class="pa-5"
                    >
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          id="accTypeField"
                          v-model="account.accountType"
                          :label="`${ $t('profile|account_type') }:`"
                          :disabled="true"
                          class="purple-input"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        v-if="company && account.accountType === 'lawyer'"
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          v-model="company.companyData.companyName"
                          :label="$t('clients|company') + ':'"
                          :disabled="true"
                          :placeholder="company.companyData.companyName"
                          class="purple-input"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          ref="email"
                          v-model="account.email"
                          :rules="[() => !!account.email || $t('common|field_required')]"
                          :placeholder="account.email"
                          :label="$t('common|email')"
                          :disabled="true"
                          class="purple-input"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          v-model="accountData.accountName"
                          :placeholder="account.accountData.accountName"
                          :label="$t('profile|account_name')"
                          class="purple-input"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          ref="firstName"
                          v-model="accountData.firstName"
                          :rules="[() => !!accountData.firstName || $t('common|field_required')]"
                          :placeholder="account.accountData.firstName"
                          :label="$t('clients|first_name')"
                          class="purple-input"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          ref="lastName"
                          v-model="accountData.lastName"
                          :rules="[() => !!accountData.lastName || $t('common|field_required')]"
                          :placeholder="account.accountData.lastName"
                          :label="$t('clients|surname')"
                          class="purple-input"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          v-model="accountData.professionalNummber"
                          :placeholder="account.accountData.professionalNummber"
                          :label="$t('profile|lawyer_id')"
                          hint="Numéro de toque / Lawyer ID number / beA SAFE-ID"
                          persistent-hint
                          class="purple-input"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          ref="address"
                          v-model="accountData.address"
                          :rules="[() => !!accountData.address || $t('common|field_required')]"
                          :placeholder="account.accountData.address"
                          :label="$t('clients|address')"
                          hint="Street and number of the house, flat, or apartment."
                          persistent-hint
                          class="purple-input"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          ref="city"
                          v-model="accountData.city"
                          :rules="[() => !!accountData.city || $t('common|field_required')]"
                          :placeholder="account.accountData.city"
                          :label="$t('clients|city')"
                          class="purple-input"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          ref="postal_code"
                          v-model="accountData.postal_code"
                          :rules="[() => !!accountData.postal_code || $t('common|field_required')]"
                          :placeholder="account.accountData.postal_code"
                          :label="$t('clients|postal_code')"
                          class="purple-input"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-autocomplete
                          ref="nationality"
                          v-model="accountData.nationality"
                          :rules="[() => !!accountData.nationality || $t('common|field_required')]"
                          :items="CountryList"
                          item-text="en_short_name"
                          item-value="en_short_name"
                          :label="$t('clients|nationality')"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          ref="phone"
                          v-model="accountData.phone"
                          :rules="[() => !!accountData.phone || $t('common|field_required')]"
                          :placeholder="account.accountData.phone"
                          :label="$t('profile|phone')"
                          class="purple-input"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          v-model="accountData.fax"
                          :placeholder="account.accountData.fax"
                          :label="$t('profile|fax')"
                          class="purple-input"
                          outlined
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-spacer />
                      <v-btn
                        rounded
                        small
                        class="font-weight-light mr-8 mb-4"
                        color="primary"
                        min-width="150"
                        @click="updateAccountData"
                      >
                        {{ $t('actions|update') }}
                      </v-btn>
                    </v-row>
                  </v-form>

                  <!-- individual type account form -->
                  <v-form
                    v-if="account.accountType === 'individual'"
                    autocomplete="off"
                  >
                    <v-row
                      class="pa-5"
                    >
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          id="accTypeField"
                          v-model="account.accountType"
                          :label="`${ $t('profile|account_type') }:`"
                          :disabled="true"
                          class="purple-input"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          ref="email"
                          v-model="account.email"
                          :rules="[() => !!account.email || $t('common|field_required')]"
                          :placeholder="account.email"
                          :label="$t('common|email')"
                          :disabled="true"
                          class="purple-input"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          v-model="accountData.accountName"
                          :placeholder="account.accountData.accountName"
                          :label="$t('profile|account_name')"
                          class="purple-input"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          v-model="accountData.phone"
                          :placeholder="account.accountData.phone"
                          :label="$t('profile|phone')"
                          class="purple-input"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          ref="firstName"
                          v-model="accountData.firstName"
                          :rules="[() => !!accountData.firstName || $t('common|field_required')]"
                          :placeholder="account.accountData.firstName"
                          :label="$t('clients|first_name')"
                          class="purple-input"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          ref="lastName"
                          v-model="accountData.lastName"
                          :rules="[() => !!accountData.lastName || $t('common|field_required')]"
                          :placeholder="account.accountData.lastName"
                          :label="$t('clients|surname')"
                          class="purple-input"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-menu
                          v-model="dateOfBirthMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              ref="date_of_birth"
                              v-model="accountData.date_of_birth"
                              :rules="[() => !!accountData.date_of_birth || $t('common|field_required')]"
                              :placeholder="account.accountData.date_of_birth"
                              class="purple-input"
                              outlined
                              dense
                              :label="$t('clients|date_of_birth')"
                              :hint="$t('common|in_format')"
                              persistent-hint
                              readonly
                              v-bind="attrs"
                              required
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="date"
                            no-title
                            @input="dateOfBirthMenu = false"
                          />
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          ref="place_of_birth"
                          v-model="accountData.place_of_birth"
                          :rules="[() => !!accountData.place_of_birth || $t('common|field_required')]"
                          :placeholder="account.accountData.place_of_birth"
                          :label="$t('clients|place_of_birth')"
                          class="purple-input"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          ref="address"
                          v-model="accountData.address"
                          :rules="[() => !!accountData.address || $t('common|field_required')]"
                          :placeholder="account.accountData.address"
                          :label="$t('clients|address')"
                          class="purple-input"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          ref="city"
                          v-model="accountData.city"
                          :rules="[() => !!accountData.city || $t('common|field_required')]"
                          :placeholder="account.accountData.city"
                          :label="$t('clients|city')"
                          class="purple-input"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          ref="postal_code"
                          v-model="accountData.postal_code"
                          :rules="[() => !!accountData.postal_code || $t('common|field_required')]"
                          :placeholder="account.accountData.postal_code"
                          :label="$t('clients|postal_code')"
                          class="purple-input"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-autocomplete
                        ref="nationality"
                        v-model="accountData.nationality"
                        :rules="[() => !!accountData.nationality || $t('common|field_required')]"
                        :items="CountryList"
                        item-text="en_short_name"
                        item-value="en_short_name"
                        :label="$t('clients|nationality')"
                        outlined
                        dense
                        class="mt-3 mx-3"
                        required
                      />
                    </v-row>
                    <v-row>
                      <v-spacer />
                      <v-btn
                        rounded
                        small
                        class="font-weight-light mr-8 mb-4"
                        color="primary"
                        min-width="150"
                        @click="updateAccountData"
                      >
                        {{ $t('actions|update') }}
                      </v-btn>
                    </v-row>
                  </v-form>

                  <!-- for company type account -->
                  <v-form v-if="account.accountType === 'company'">
                    <v-row
                      class="pa-5"
                    >
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          id="accTypeField"
                          v-model="account.accountType"
                          :label="`${ $t('profile|account_type') }:`"
                          :disabled="true"
                          class="purple-input"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          ref="email"
                          v-model="account.email"
                          :rules="[() => !!account.email || $t('common|field_required')]"
                          :placeholder="account.email"
                          :label="$t('common|email')"
                          :disabled="true"
                          class="purple-input"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          v-model="accountData.accountName"
                          :placeholder="account.accountData.accountName"
                          :label="$t('profile|account_name')"
                          class="purple-input"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          v-model="accountData.phone"
                          :placeholder="account.accountData.phone"
                          :label="$t('profile|phone')"
                          class="purple-input"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          ref="companyName"
                          v-model="accountData.companyName"
                          :rules="[() => !!accountData.companyName || $t('common|field_required')]"
                          :placeholder="account.accountData.companyName"
                          :label="$t('clients|company_name')"
                          class="purple-input"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          ref="companyType"
                          v-model="accountData.companyType"
                          :rules="[() => !!accountData.companyType || $t('common|field_required')]"
                          :placeholder="account.accountData.companyType"
                          :label="$t('clients|company_type')"
                          :hint="$t('common|example') + ':' + ' ' + 'SARL, SAS, GMbH, EOOD, Inc., Ltd.'"
                          persistent-hint
                          class="purple-input"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <!-- :rules are actualy not active because typing of string is not allowed. if needed disable allowOnlyNumbers -->
                        <v-text-field
                          ref="sharedCapital"
                          v-model.number="accountData.sharedCapital"
                          :hint="$t('expressions|only_numbers_are_allowed')"
                          type="numbers"
                          :rules="[() => !!accountData.sharedCapital || $t('common|field_required')]"
                          :label="$t('clients|share_capital')"
                          class="purple-input"
                          outlined
                          dense
                          required
                          @input="allowOnlyNumbers"
                        >
                          <v-menu
                            slot="prepend-inner"
                            size="18"
                            class="ml-n3 mt-1"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                v-bind="attrs"
                                v-on="on"
                              >
                                <div
                                  v-if="accountData.selectedCurrency"
                                  class="mr-2 mt-1"
                                >
                                  {{ accountData.selectedCurrency.symbol }}
                                  <v-icon
                                    size="18"
                                    class="ml-n2"
                                  >
                                    mdi-menu-down
                                  </v-icon>
                                </div>
                                <div
                                  v-else
                                  class="mr-2 mt-1"
                                >
                                  {{ Currencies.EUR.symbol }}
                                  <v-icon
                                    size="18"
                                    class="ml-n2"
                                  >
                                    mdi-menu-down
                                  </v-icon>
                                </div>
                              </div>
                            </template>
                            <v-list
                              style="max-height: 400px; overflow-y: auto;"
                            >
                              <v-list-item
                                v-for="(currency, index) in Currencies"
                                :key="index"
                                @click="accountData.selectedCurrency = currency"
                              >
                                <v-list-item-title>{{ currency.symbol }} - {{ currency.name }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                          <div
                            v-if="accountData.selectedCurrency"
                            slot="label"
                            :class="accountData.selectedCurrency.symbol.length >= 2 ? 'mr-8' : ''"
                          >
                            {{ $t('clients|share_capital') }}
                          </div>
                        </v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          ref="rcsNumber"
                          v-model="accountData.rcsNumber"
                          :rules="[() => !!accountData.rcsNumber || $t('common|field_required')]"
                          :placeholder="account.accountData.rscNumber"
                          :label="$t('profile|RCS_Number')"
                          class="purple-input"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          ref="place_of_registration"
                          v-model="accountData.place_of_registration"
                          :rules="[() => !!accountData.place_of_registration || $t('common|field_required')]"
                          :placeholder="account.accountData.place_of_registration"
                          :label="$t('clients|company_registration_place')"
                          class="purple-input"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          ref="address"
                          v-model="accountData.address"
                          :rules="[() => !!accountData.address || $t('common|field_required')]"
                          :placeholder="account.accountData.address"
                          :label="$t('clients|address')"
                          class="purple-input"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          ref="city"
                          v-model="accountData.city"
                          :rules="[() => !!accountData.city || $t('common|field_required')]"
                          :placeholder="account.accountData.city"
                          :label="$t('clients|city')"
                          class="purple-input"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-autocomplete
                          ref="nationality"
                          v-model="accountData.nationality"
                          :rules="[() => !!accountData.nationality || $t('common|field_required')]"
                          :items="CountryList"
                          item-text="en_short_name"
                          item-value="alpha_2_code"
                          :label="$t('clients|nationality')"
                          outlined
                          dense
                          class="mx-1"
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          ref="postal_code"
                          v-model="accountData.postal_code"
                          :rules="[() => !!accountData.postal_code || $t('common|field_required')]"
                          :placeholder="account.accountData.postal_code"
                          :label="$t('clients|postal_code')"
                          class="purple-input"
                          outlined
                          dense
                          required
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          v-model="accountData.companyRepresentativeName"
                          :placeholder="account.accountData.companyRepresentativeName"
                          :label="$t('clients|company_representative')"
                          class="purple-input"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="4"
                      >
                        <v-text-field
                          v-model="accountData.companyRepresentativeEmail"
                          :placeholder="account.accountData.companyRepresentativeEmail"
                          :label="$t('mixed|representative_email')"
                          class="purple-input"
                          outlined
                          dense
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-spacer />
                      <v-btn
                        rounded
                        small
                        class="font-weight-light mr-8 mb-4"
                        color="primary"
                        min-width="150"
                        @click="updateAccountData"
                      >
                        {{ $t('actions|update') }}
                      </v-btn>
                    </v-row>
                  </v-form>
                </v-card>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container
                fill-height
                fluid
              >
                <v-card
                  width="100%"
                  height="100%"
                  flat
                  :style="`height: ${($vuetify.breakpoint.height - 210).toString() + 'px'}; overflow-y: auto;`"
                >
                  <v-card-text class="mt-4">
                    <div class="small-title">
                      {{ $t('notifications|notifications') }}
                    </div>
                    <div
                      style="display: flex; align-items: flex-start;"
                    >
                      {{ $t('expressions|get_e-mail_notifications') }}
                      <v-switch
                        v-model="accountData.emailNotifications"
                        color="green"
                        :value="true"
                        hide-details
                        class="mt-n1 mb-1 ml-12"
                        @click="toggleEmailNotifications"
                      />
                    </div>
                    <v-divider
                      class="mt-3"
                    />
                    <div class="mt-4 small-title">
                      {{ $t('common|password') }}
                    </div>
                    <div>
                      {{ $t('expressions|change_the_password_of_your_account') }}
                      <v-btn
                        small
                        text
                        class="btn-text ml-8"
                        @click="expandPasswordFields = true"
                      >
                        {{ $t('actions|change_password') }}
                        <v-icon
                          small
                        >
                          mdi-chevron-right
                        </v-icon>
                      </v-btn>
                    </div>
                    <v-expand-transition>
                      <v-card
                        v-show="expandPasswordFields"
                        flat
                        height="100%"
                        width="40%"
                        class="mt-4"
                      >
                        <v-card-text>
                          <v-text-field
                            v-model="actualPassword"
                            outlined
                            dense
                            :error-messages="passwordErrors"
                            required
                            autocomplete="new-password"
                            :label="$t('common|actual_password')"
                            class="purple-input my-1"
                            :type="showPass ? 'text' : 'password'"
                            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                            @input="$v.actualPassword.$touch()"
                            @blur="$v.actualPassword.$touch()"
                            @click:append="showPass = !showPass"
                          />
                          <v-text-field
                            v-model="newPassword"
                            outlined
                            dense
                            autocomplete="new-password"
                            :error-messages="newPasswordErrors"
                            :type="showPass ? 'text' : 'password'"
                            :append-icon="showPassNew ? 'mdi-eye' : 'mdi-eye-off'"
                            required
                            :label="$t('common|new_password')"
                            class="purple-input my-1"
                            @input="$v.newPassword.$touch()"
                            @blur="$v.newPassword.$touch()"
                            @click:append="showPassNew = !showPassNew"
                          />
                          <v-text-field
                            v-model="confirmPassword"
                            outlined
                            dense
                            autocomplete="new-password"
                            :error-messages="confirmPasswordErrors"
                            :type="showPass ? 'text' : 'password'"
                            :append-icon="showPassConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                            required
                            :label="$t('common|confirm_new_password')"
                            class="purple-input my-1"
                            @input="$v.confirmPassword.$touch()"
                            @blur="$v.confirmPassword.$touch()"
                            @click:append="showPassConfirm = !showPassConfirm"
                          />
                        </v-card-text>
                        <v-card-actions>
                          <v-btn
                            x-small
                            outlined
                            @click="expandPasswordFields = false; actualPassword = null"
                          >
                            {{ $t('actions|cancel') }}
                          </v-btn>
                          <v-spacer />
                          <v-btn
                            x-small
                            rounded
                            color="primary"
                            min-width="150"
                            :disabled="!actualPassword || !newPassword || !confirmPassword"
                            @click="changePassword()"
                          >
                            {{ $t('actions|submit') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-expand-transition>
                    <v-divider
                      class="my-6"
                    />
                    <div v-if="account && account.subscriptionStatus === 'paid'">
                      <div class="mt-4 small-title">
                        {{ $t('payments|billing_address') }}
                      </div>
                      <div>
                        {{ $t('actions|change_your_billing_address') }}
                        <v-btn
                          small
                          text
                          class="btn-text ml-8"
                          @click="expandBillingAdressFields = true"
                        >
                          {{ $t('actions|change_billing_address') }}
                          <v-icon
                            small
                          >
                            mdi-chevron-right
                          </v-icon>
                        </v-btn>
                      </div>
                      <v-expand-transition>
                        <v-card
                          v-show="expandBillingAdressFields"
                          flat
                          height="100%"
                          width="40%"
                          class="mt-4"
                        >
                          <v-card-text>
                            <v-text-field
                              v-model="accountData.billingName"
                              dense
                              outlined
                              :label="$t('common|name')"
                            />
                            <v-text-field
                              v-model="accountData.billingAddress.city"
                              dense
                              outlined
                              :label="$t('clients|city')"
                            />
                            <v-text-field
                              v-model="accountData.billingAddress.country"
                              dense
                              outlined
                              :label="$t('profile|country')"
                            />
                            <v-text-field
                              v-model="accountData.billingAddress.line1"
                              dense
                              outlined
                              :label="$t('clients|address')"
                            />
                            <v-text-field
                              v-model="accountData.billingAddress.line2"
                              dense
                              outlined
                              :label="$t('clients|address')"
                            />
                            <v-text-field
                              v-model="accountData.billingAddress.postal_code"
                              dense
                              outlined
                              :label="$t('clients|postal_code')"
                            />
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                              x-small
                              outlined
                              @click="expandBillingAdressFields = false"
                            >
                              {{ $t('actions|cancel') }}
                            </v-btn>
                            <v-spacer />
                            <v-btn
                              x-small
                              rounded
                              color="primary"
                              min-width="150"
                              @click="changeBillingAddress()"
                            >
                              {{ $t('actions|submit') }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-expand-transition>
                      <v-divider
                        class="my-6"
                      />
                    </div>
                    <div v-if="account.subscriptionStatus !== 'free'">
                      <div
                        class="small-title"
                      >
                        {{ $t('payments|update_payment_details') }}
                      </div>
                      <div>
                        {{ $t('payments|change_credit_card_details') }}
                        <v-btn
                          small
                          text
                          class="btn-text ml-8"
                          @click="updatePaymentDetails()"
                        >
                          {{ $t('actions|change_details') }}
                          <v-icon
                            small
                          >
                            mdi-chevron-right
                          </v-icon>
                        </v-btn>
                      </div>
                      <v-divider
                        class="my-6"
                      />
                    </div>
                    <div class="small-title">
                      {{ $t('payments|subscription') }}
                    </div>
                    <div v-if="account.subscriptionStatus === 'free'">
                      {{ $t('payments|actual_payments_status') }}:
                      <span style="font-weight: bold;">
                        {{ translatePaymentsStatus(account.subscriptionStatus) }}
                      </span>
                    </div>
                    <div v-if="account.subscriptionStatus === 'trial'">
                      {{ $t('payments|actual_payments_status') }}:
                      <span style="font-weight: bold;">
                        {{ translatePaymentsStatus(account.subscriptionStatus) }} - {{ daysLeft }} {{ $t('common|days_left') }}.
                      </span>
                    </div>
                    <div v-if="account.subscriptionStatus === 'paid'">
                      {{ $t('payments|actual_payments_status') }}:
                      <span style="font-weight: bold;">
                        {{ translatePaymentsStatus(account.subscriptionStatus) }}
                      </span>
                    </div>
                    <div v-if="account.subscriptionStatus === 'unpaid'">
                      {{ $t('payments|actual_payments_status') }}:
                      <span style="font-weight: bold;">
                        {{ translatePaymentsStatus(account.subscriptionStatus) }}
                      </span>
                      <v-btn
                        small
                        text
                        class="btn-text ml-8"
                        @click="$router.push('/activate-account')"
                      >
                        {{ $t('actions|activate_account') }}
                        <v-icon
                          small
                        >
                          mdi-chevron-right
                        </v-icon>
                      </v-btn>
                    </div>
                    <div v-if="account.subscriptionStatus === 'paid'">
                      {{ $t('payments|stop_payments') }}
                      <v-btn
                        small
                        text
                        class="btn-text ml-8"
                        @click="cancelSubscription()"
                      >
                        {{ $t('actions|cancel') }}
                        <v-icon
                          small
                        >
                          mdi-chevron-right
                        </v-icon>
                      </v-btn>
                    </div>
                    <div v-if="account.subscriptionStatus === 'company paid'">
                      {{ $t('payments|actual_payments_status') }}:
                      <span style="font-weight: bold;">
                        {{ account.subscriptionStatus }}
                      </span>
                    </div>
                    <v-divider
                      class="my-6"
                    />
                    <div class="small-title">
                      {{ $t('common|deactivation') }}
                    </div>
                    <div>
                      {{ $t('expressions|leave_your_workspaces') }}
                      <v-btn
                        small
                        text
                        class="btn-text ml-8"
                        @click="expandDeactivationMessage = true"
                      >
                        {{ $t('actions|delete_account') }}
                        <v-icon
                          small
                        >
                          mdi-chevron-right
                        </v-icon>
                      </v-btn>
                    </div>
                    <v-expand-transition>
                      <v-card
                        v-show="expandDeactivationMessage"
                        flat
                        height="100%"
                        width="40%"
                        class="mt-4"
                      >
                        <v-card-text>
                          {{ $t('expressions|delete_account_info') }}
                          <v-textarea
                            v-model="reason"
                            outlined
                            :placeholder="$t('expressions|reason_for_account_deactivation')"
                            class="mt-3"
                          />
                        </v-card-text>
                        <v-card-actions>
                          <v-btn
                            x-small
                            outlined
                            @click="expandDeactivationMessage = false"
                          >
                            {{ $t('actions|cancel') }}
                          </v-btn>
                          <v-spacer />
                          <v-btn
                            x-small
                            rounded
                            color="primary"
                            min-width="150"
                            @click="deleteUserAccount()"
                          >
                            {{ $t('actions|delete') }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-expand-transition>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-tab-item>
            <!-- <v-tab-item>
              <v-container
                fill-height
                fluid
              >
                <theme-changer-menu
                  :theme="account.accountData.theme"
                  @changeTheme="updateTheme"
                />
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container
                fill-height
                fluid
              >
                <v-card
                  width="60%"
                  height="100%"
                  class="ma-auto"
                >
                  <v-list
                    class="document-list-container app-scrollbar pa-2"
                  >
                    <v-list-item-group
                      v-model="selectedItem"
                      color="primary"
                    >
                      <v-list-item
                        v-for="(item, i) in items"
                        :key="i"
                      >
                        <v-list-item-icon>
                          <v-icon
                            color="#c11e07"
                            v-text="item.icon"
                          />
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.text"
                          />
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-container>
            </v-tab-item> -->
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card>
    <template>
      <StripeCheckout
        ref="checkoutRef"
        :pk="publishableKey"
        :session-id="sesId"
        @loading="v => loading = v"
      />
    </template>
  </v-container>
</template>

<script>
import {
  mapState,
  mapActions,
  mapMutations
} from 'vuex'
import {
  required,
  minLength,
  helpers
} from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import myUpload from 'vue-image-crop-upload/upload-2.vue'
import CountryList from '@/enums/CountryList.json'
import Currencies from '@/enums/Currencies.json'
import cfg from '@/config.js'
import autobahn from 'autobahn'
import moment from 'moment'
const alphaNum = helpers.regex('alphaNumAndDot', /^[a-z\d.!$%&()*+,./<=>?@[\]^_|~«»]*$/i)

export default {
  components: { myUpload, StripeCheckout },
  mixins: [validationMixin],
  validations: {
    actualPassword: { required, minLength: minLength(7), alphaNum },
    newPassword: { required, minLength: minLength(7), alphaNum },
    confirmPassword: { required, minLength: minLength(7), alphaNum }
  },
  data () {
    return {
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      sesId: null,
      accountData: null,
      CountryList,
      Currencies,
      rules: {
        charType: v => !isNaN(v) || 'Only numbers are allowed'
      },
      hoverAvatarIcon: false,
      selectedItem: 1,
      componentKey: 0,
      selectedCurrency: null,
      dateOfBirthMenu: false,
      date: null,
      dateOfRegistration: null,
      items: [
        { text: 'Invoice 1', icon: 'mdi-file-pdf' },
        { text: 'Invoice 2', icon: 'mdi-file-pdf' },
        { text: 'Invoice 3', icon: 'mdi-file-pdf' }
      ],
      selectedTab: 0,
      show: false,
      params: {
        fileOriginalName: '',
        fileType: '',
        fileSize: null
      },
      headers: {
        'Authorization': ''
      },
      imgDataUrl: '', // the datebase64 url of created image
      disabledButtonRuleVmodel: false,
      cfg,
      expandPasswordFields: false,
      expandBillingAdressFields: false,
      expandDeactivationMessage: false,
      actualPassword: null,
      newPassword: null,
      confirmPassword: null,
      showPass: false,
      showPassNew: false,
      showPassConfirm: false,
      reason: '',
      message: '',
      daysLeft: null,
      loading: false
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      token: state => state.auth.token
    }),
    tabs () {
      return [
        {
          text: this.$t('profile|profile'),
          index: 0
        },
        {
          text: this.$t('profile|account_settings'),
          index: 1
        }
        // {
        //   text: 'Color settings',
        //   index: 2,
        //   disabled: true
        // },
        // {
        //   text: 'Billing',
        //   index: 3,
        //   disabled: true
        // }
      ]
    },
    passwordErrors () {
      const errors = []

      if (!this.$v.actualPassword.$dirty) return errors

      !this.$v.actualPassword.required && errors.push(this.$t('warning|password_required'))
      !this.$v.actualPassword.minLength && errors.push(this.$t('warning|password_type'))
      !this.$v.actualPassword.alphaNum && errors.push('Only alphanumeric characters are allowed!')

      return errors
    },
    newPasswordErrors () {
      const errors = []

      if (!this.$v.newPassword.$dirty) return errors

      !this.$v.newPassword.required && errors.push(this.$t('warning|password_required'))
      !this.$v.newPassword.minLength && errors.push(this.$t('warning|password_type'))
      !this.$v.newPassword.alphaNum && errors.push('Only alphanumeric characters are allowed!')

      return errors
    },
    confirmPasswordErrors () {
      const errors = []

      if (!this.$v.confirmPassword.$dirty) return errors

      !this.$v.confirmPassword.required && errors.push(this.$t('warning|password_required'))
      !this.$v.confirmPassword.minLength && errors.push(this.$t('warning|password_type'))
      !this.$v.confirmPassword.alphaNum && errors.push('Only alphanumeric characters are allowed!')

      return errors
    },
    form () {
      return {
        email: this.account.email,
        firstName: this.accountData.firstName,
        lastName: this.accountData.lastName,
        address: this.accountData.address,
        city: this.accountData.city,
        postal_code: this.accountData.postal_code,
        nationality: this.accountData.nationality,
        phone: this.accountData.phone
      }
    },
    individualClientForm () {
      return {
        firstName: this.accountData.firstName,
        lastName: this.accountData.lastName,
        date_of_birth: this.accountData.date_of_birth,
        place_of_birth: this.accountData.place_of_birth,
        address: this.accountData.address,
        city: this.accountData.city,
        postal_code: this.accountData.postal_code,
        nationality: this.accountData.nationality
      }
    },
    companyClientForm () {
      return {
        email: this.accountData.email,
        companyName: this.accountData.companyName,
        companyType: this.accountData.companyType,
        sharedCapital: this.accountData.sharedCapital,
        rcsNumber: this.accountData.rcsNumber,
        place_of_registration: this.accountData.place_of_registration,
        address: this.accountData.address,
        city: this.accountData.city,
        postal_code: this.accountData.postal_code,
        nationality: this.accountData.nationality
      }
    }
    // disabledButtonRule () {
    //   let rule
    //   if (this.account.accountType === 'individual') {
    //     if (this.account.email && this.accountData.accountName && this.accountData.phone && this.accountData.firstName &&
    //     this.accountData.lastName && this.accountData.date_of_birth && this.accountData.place_of_birth && this.accountData.nationality &&
    //     this.accountData.address && this.accountData.city && this.accountData.postal_code) {
    //       rule = false
    //     } else {
    //       rule = true
    //     }
    //   }
    //   if (this.account.accountType === 'company') {
    //     if (this.account.email && this.accountData.accountName && this.accountData.phone && this.accountData.companyName &&
    //     this.accountData.companyType && this.accountData.sharedCapital && this.accountData.rcsNumber &&
    //     this.accountData.date_of_registration && this.accountData.address &&
    //     this.accountData.city && this.accountData.nationality && this.accountData.postal_code && this.accountData.companyRepresentativeName &&
    //     this.accountData.companyRepresentativeEmail) {
    //       rule = false
    //     } else {
    //       rule = true
    //     }
    //   }
    //   if (this.account.accountType === 'lawyer') {
    //     if (this.account.email && this.accountData.phone && this.accountData.firstName &&
    //     this.accountData.lastName && this.accountData.nationality &&
    //     this.accountData.address && this.accountData.city && this.accountData.postal_code) {
    //       rule = false
    //     } else {
    //       rule = true
    //     }
    //   }
    //   return rule
    // }
    // countryFilter (item, queryText, itemText) {
    //   const textOne = item.nationality.toLowerCase()
    //   const textTwo = item.en_short_name.toLowerCase()
    //   const searchText = queryText.toLowerCase()

    //   return textOne.indexOf(searchText) > -1 ||
    //     textTwo.indexOf(searchText) > -1
    // },
  },
  watch: {
    account: {
      handler (value) {
        if (value) this.accountData = value.accountData || {}
      },
      immediate: true
    },
    date (val) {
      if (val) {
        this.formatDate(val)
      }
    },
    dateOfRegistration (val) {
      if (val) {
        this.formatRegistrationDate(val)
      }
    },
    disabledButtonRule (val) {
      if (val) {
        this.disabledButtonRuleVmodel = true
      } else {
        this.disabledButtonRuleVmodel = false
      }
    }
  },
  mounted () {
    setTimeout(() => {
      const el = document.getElementById('accTypeField')
      if (el && el.value === 'lawyer') el.value = this.$t('profile|lawyer')
      if (el && el.value === 'individual') el.value = this.$t('profile|individual')
      if (el && el.value === 'company') el.value = this.$t('clients|company')
    }, 300)
    let accountCreatedAt = moment(this.account.createdAt)
    let now = moment()
    let days = now.diff(accountCreatedAt, 'days')
    // console.log(`${days} days have passed since the account was created.`)
    this.daysLeft = 30 - days
  },
  created () {
    this.toggleDrawer(true)
    if (this.disabledButtonRule === true) {
      this.disabledButtonRuleVmodel = true
    }
  },
  methods: {
    ...mapActions({
      getAndSetAccount: 'account/getAndSetAccount',
      updateAccount: 'account/updateAccount',
      updateAccountPassword: 'account/updateAccountPassword',
      updateEmailNotifications: 'account/updateEmailNotifications',
      deleteAccount: 'account/deleteAccount',
      logUserOut: 'account/logUserOut',
      addToast: 'toasts/addToast',
      resetClients: 'clients/resetClients',
      resetCompanyClients: 'companyClients/resetCompanyClients',
      resetGroupClients: 'groupClients/resetGroupClients',
      resetCases: 'cases/resetCases',
      resetCaseForms: 'caseForms/resetCaseForms',
      resetTemplates: 'templates/resetTemplates',
      resetDocuments: 'documents/resetDocuments',
      resetCompany: 'company/resetCompany',
      resetCompanyCases: 'companyCases/resetCompanyCases',
      resetCompanyCaseForms: 'companyCaseForms/resetCompanyCaseForms',
      resetCompanyTemplates: 'companyTemplates/resetCompanyTemplates',
      resetCompanyDocuments: 'companyDocuments/resetCompanyDocuments',
      resetGroupCases: 'groupCases/resetGroupCases',
      resetGroupCaseForms: 'groupCaseForms/resetGroupCaseForms',
      resetGroupTemplates: 'groupTemplates/resetGroupTemplates',
      resetGroupDocuments: 'groupDocuments/resetGroupDocuments',
      removeToken: 'auth/removeToken',
      resetAccount: 'account/resetAccount'
    }),
    ...mapMutations({
      toggleDrawer: 'app/toggleDrawer'
    }),
    translatePaymentsStatus (input) {
      if (input === 'paid') return this.$t('payments|paid')
      if (input === 'unpaid') return this.$t('payments|unpaid')
      if (input === 'company paid') return this.$t('payments|company_paid')
      if (input === 'trial') return this.$t('payments|trial')
      if (input === 'free') return this.$t('common|free')
    },
    formatDate (date) {
      const [year, month, day] = date.split('-')
      this.accountData.date_of_birth = `${day}/${month}/${year}`
    },
    formatRegistrationDate (date) {
      const [year, month, day] = date.split('-')
      this.accountData.date_of_registration = `${day}/${month}/${year}`
    },
    forceRerender () {
      this.componentKey++
    },
    allowOnlyNumbers (input) {
      if (isNaN(input)) {
        this.$refs.sharedCapital.reset()
        this.rules.charTpe = !isNaN(input)
      }
    },
    async updateAccountData () {
      let blankFields = []
      if (this.account.accountType === 'lawyer') {
        Object.keys(this.form).forEach(f => {
          this.$refs[f].validate(true)
          if (this.$refs[f].validate(true) === false) {
            blankFields.push(f)
          }
        })
      }
      if (this.account.accountType === 'individual') {
        Object.keys(this.individualClientForm).forEach(f => {
          if (this.$refs[f].validate(true) === false) {
            blankFields.push(f)
          }
        })
      }
      if (this.account.accountType === 'company') {
        Object.keys(this.companyClientForm).forEach(f => {
          this.$refs[f].validate(true)
          if (this.$refs[f].validate(true) === false) {
            blankFields.push(f)
          }
        })
      }
      if (!blankFields.length) {
        try {
          this.accountData.email = this.account.email
          this.accountData.requiredFieldsFilled = true
          await this.updateAccount({ accountData: this.accountData })
          this.addToast({
            title: this.$t('profile|updated_sucessfully'),
            color: 'white',
            snackbarColor: 'success'
          })
        } catch (error) {
          this.addToast({
            title: this.$t('profile|update_failed'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      } else {
        this.addToast({
          title: this.$t('profile|empty_field'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async toggleEmailNotifications () {
      const _id = this.account._id
      const payload = {
        emailNotifications: this.accountData.emailNotifications
      }
      try {
        await this.updateEmailNotifications({ _id, payload })
      } catch (error) {
        console.error(error, 'ERROR')
      }
    },
    async updateTheme (theme) {
      this.updateAccount({ accountData: { ...this.accountData, theme } })
    },
    uploadProfilePicture () {
      this.show = !this.show
    },
    cropSuccess (imgDataUrl, field) {
      this.imgDataUrl = imgDataUrl
      // const token = localStorage.getItem('access_token')
      if (this.token) {
        this.headers.Authorization = 'Bearer ' + this.token
      }
    },
    cropUploadSuccess (jsonData, field) {
      if (jsonData) {
        this.forceRerender()
        this.$store.commit('account/UPDATE_ACCOUNT', jsonData.data)
      }
    },
    cropUploadFail (status, field) {
      console.log('-------- upload fail --------')
      console.log(status)
      console.log('field: ' + field)
    },
    srcFileSet (fileName, fileType, fileSize) {
      this.params._id = this.account._id
      this.params.fileOriginalName = fileName
      this.params.fileType = fileType
      this.params.fileSize = fileSize
    },
    async changePassword () {
      if (this.newPassword !== this.confirmPassword) {
        this.addToast({
          title: this.$t('error|password_mismatch'),
          color: 'white',
          snackbarColor: 'error'
        })
      } else {
        const payload = {
          actualPassword: this.actualPassword,
          newPassword: this.newPassword,
          confirmPassword: this.confirmPassword
        }
        try {
          await this.updateAccountPassword(payload)
          this.$router.push('/login')
          this.addToast({
            title: this.$t('message|password_update_success'),
            color: 'white',
            snackbarColor: 'success'
          })
        } catch (error) {
          this.addToast({
            title: this.$t('error|password_update_success'),
            color: 'white',
            snackbarColor: 'error'
          })
        }
      }
    },
    async deleteUserAccount () {
      if (
        this.account.companyId && this.company &&
        this.company.members && this.company.members.length > 1 &&
        this.account.currentUserRole && this.account.currentUserRole === 'adminOwner'
      ) {
        this.addToast({
          title: `${this.$t('warning|admin_owner_in_company')} ${this.company.companyData.companyName}.
          ${this.$t('warning|select_another_admin_owner')}`,
          color: 'white',
          snackbarColor: 'error'
        })
        return
      }
      const _id = this.account._id
      const payload = {
        reason: this.reason
      }
      try {
        const res = await this.$dialog.confirm({
          title: this.$t('common|warning'),
          text: this.$t('common|are_you_sure') + ' ' + this.$t('warning|account_and_its_resources_cannot_be_restored')
        })
        if (res) {
          await this.deleteAccount({ _id, payload })
          await this.logUserOut()
          await this.resetClients()
          await this.resetCompanyClients()
          await this.resetGroupClients()
          await this.resetCases()
          await this.resetCaseForms()
          await this.resetTemplates()
          await this.resetDocuments()
          await this.resetCompany()
          await this.resetCompanyCases()
          await this.resetCompanyCaseForms()
          await this.resetCompanyTemplates()
          await this.resetCompanyDocuments()
          await this.resetGroupCases()
          await this.resetGroupCaseForms()
          await this.resetGroupTemplates()
          await this.resetGroupDocuments()
          await this.removeToken()
          await this.resetAccount()
          localStorage.clear()
          this.$router.push('/login')
        }
      } catch (error) {
        this.addToast({
          title: this.$t('error|account_delete_fail'),
          color: 'white',
          snackbarColor: 'error'
        })
      }
    },
    async changeBillingAddress () {
      return new Promise(async (resolve, reject) => {
        try {
          var connection = new autobahn.Connection({
            url: process.env.VUE_APP_CROSSBAR_PATH,
            realm: process.env.VUE_APP_CROSSBAR_REALM
          })
          connection.onopen = async (session) => {
            const result = await session.call('lavvira.stripe_controller.update_billing_details',
              [
                this.account._id,
                'account',
                this.account.accountData.billingName,
                this.account.accountData.billingAddress
              ])
            if (result === 'success') {
              await this.getAndSetAccount()
              this.addToast({
                title: this.$t('expressions|successfully_changed_billing_address'),
                color: 'white',
                snackbarColor: 'success'
              })
            } else {
              this.addToast({
                title: this.$t('error|something_went_wrong'),
                color: 'white',
                snackbarColor: 'error'
              })
            }
          }
          connection.open()
        } catch (e) {
          reject(e)
        }
      })
    },
    async updatePaymentDetails () {
      let result = null
      let n = 0
      this.message = this.$t('message|proceed_to_checkout')
      this.loading = true
      try {
        var connection = new autobahn.Connection({
          url: process.env.VUE_APP_CROSSBAR_PATH,
          realm: process.env.VUE_APP_CROSSBAR_REALM
        })
        connection.onopen = async (session) => {
          const makeSessionCall = async (account) => {
            const _res = await session.call('lavvira.stripe_controller.update_payment_details',
              [
                account._id,
                'account'
              ])
            return _res
          }
          // try 5 times to get the sessionId from backend
          while (n <= 5) {
            result = await makeSessionCall(this.account)
            const parsedRes = JSON.parse(result)
            if (parsedRes && !parsedRes.stripe_error) n = 6
            else n++
          }
          const res = JSON.parse(result)
          if (res && !res.stripe_error) {
            const res = JSON.parse(result)
            this.sesId = res.session_id
            setTimeout(() => {
              this.loading = false
              this.$refs.checkoutRef.redirectToCheckout()
            }, 3000)
          } else {
            this.loading = false
            this.addToast({
              title: this.$t('error|something_went_wrong'),
              color: 'white',
              snackbarColor: 'error'
            })
            // this.$router.push('/members')
          }
        }
        connection.onclose = function (reason, details) {
          // handle connection lost
          console.log(reason, details, 'connection.onclose')
        }
        connection.open()
      } catch (e) {
        console.error(e, 'WAMP ERROR')
        this.loading = false
      }
    },
    async cancelSubscription () {
      const res = await this.$dialog.confirm({
        title: this.$t('common|warning'),
        text: this.$t('common|are_you_sure') + ' ' + this.$t('warning|suspending_payments')
      })
      if (!res) return
      return new Promise(async (resolve, reject) => {
        try {
          var connection = new autobahn.Connection({
            url: process.env.VUE_APP_CROSSBAR_PATH,
            realm: process.env.VUE_APP_CROSSBAR_REALM
          })
          connection.onopen = async (session) => {
            const result = await session.call('lavvira.stripe_controller.cancel_subscription',
              [
                this.account._id,
                'account'
              ])
            if (result === 'success') {
              await this.getAndSetAccount()
              this.addToast({
                title: this.$t('payments|successful_payment_cancelation'),
                color: 'white',
                snackbarColor: 'success'
              })
              this.$vuetify.theme.themes.light.primary = '#546E7A'
            } else {
              this.addToast({
                title: this.$t('error|error_cancel_subscription'),
                color: 'white',
                snackbarColor: 'error'
              })
            }
          }
          connection.open()
        } catch (e) {
          reject(e)
        }
      })
    }
    // onActualLanguageChange (actualLanguage) {
    //   Object.assign(this.accountData, { actualLanguage })
    //   this.updateAccountData()
    // }
  }
}
</script>

<style>
.v-text-field-bussines-card > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.v-offset {
  margin: 0 auto;
  max-width: calc(100% - 32px);
  position: relative;
}
.document-list {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.document-list-container {
  flex-grow: 1;
  overflow-y: auto;
}
.icon {
  position: absolute;
  left: 0;
}
.grow-class {
  transition: all .2s ease-in-out;
}
.grow:hover {
  transform: scale(1.1);
}
.btn-text {
  text-transform:none !important;
}
.small-title {
  font-weight: bold;
}
</style>
